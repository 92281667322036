import * as Qty from "js-quantities";
import {BryxPreferences} from "./preferenceManager";

export function range(start: number, stop?: number, step?: number) {
    if (stop == undefined) {
        stop = start;
        start = 0;
    }

    if (step == undefined) {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    const result = [];
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
}

export function nullIfBlank(s: string | null): string | null {
    return s == "" ? null : s;
}

export function toTitleCase(s: string): string {
    return s.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function arraysEqual<T>(a: T[], b: T[], equalityTest: (e1: T, e2: T) => boolean): boolean {
    if (a === b) {
        return true;
    }
    if (a.length != b.length) {
        return false;
    }
    for (let i = 0; i < a.length; ++i) {
        if (!equalityTest(a[i], b[i])) {
            return false;
        }
    }
    return true;
}

export function normalizedQuantity(q: Qty, preferences: BryxPreferences) {
    if (preferences.useMetricUnits) {
        if (q.lt(Qty("500 m"))) {
            return q.to("m").toPrec("1 m");
        } else {
            return q.to("km").toPrec("0.1 km");
        }
    } else {
        if (q.lt(Qty("1000 ft"))) {
            return q.to("ft").toPrec("1 ft");
        } else {
            return q.to("mi").toPrec("0.1 mi");
        }
    }
}
