import * as i18n from "i18next";
import {DivIcon, FitBoundsOptions, Icon, latLngBounds, LatLngBoundsExpression, Zoom} from "leaflet";
import * as React from 'react';
import {LayersControl, Map, TileLayer, Viewport} from "react-leaflet";
import {Hydrant} from "../models/hydrant";
import {JobType} from "../models/jobTypeInformation";
import {MapClient, MapClientType} from "../models/mapClient";
import {BryxApi} from "../utils/bryxApi";
import {BryxLocal} from "../utils/bryxLocal";
import {LeafletControl} from "./leafletControl";

interface BryxMapProps {
    animate?: boolean;
    bounds?: LatLngBoundsExpression;
    boundsOptions?: FitBoundsOptions;
    className?: string;
    style?: React.CSSProperties;
    useFlyTo?: boolean;
    scrollWheelZoom?: Zoom;
    id?: string;
    onClickFullscreen?: () => void;
    children?: (JSX.Element | null)[];
}

interface BryxMapState {
    viewportStatus: { key: "pending" } | { key: "systemControlled" } | { key: "userControlled", viewport: Viewport };
}

type MapLayerType = { i18nKey: string, id: string };

export class BryxMap extends React.Component<BryxMapProps, BryxMapState> {
    constructor(props: BryxMapProps, context: any) {
        super(props, context);

        this.state = {
            viewportStatus: {key: "pending"},
        };
    }

    public static readonly genericIcon = new Icon({
        iconUrl: "/resources/assets/generic_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly stationIcon = new Icon({
        iconUrl: "/resources/assets/station_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly fireIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/fire_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly emsIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/ems_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly infoIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/info_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly waterIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/water_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly policeIcon = new Icon({
        iconUrl: "/resources/assets/job_map_pins/police_pin.svg",
        iconSize: [23, 45],
        iconAnchor: [11.5, 45],
    });

    public static readonly myLocationIcon = new Icon({
        iconUrl: "/resources/assets/my_location.gif",
        iconSize: [15, 15],
        iconAnchor: [7.5, 7.5],
    });

    private static readonly unitedStatesBounds = latLngBounds([24.7433195, -124.7844079], [49.3457868, -66.9513812]);

    private static readonly ACCESS_TOKEN = "pk.eyJ1IjoidGVicm93biIsImEiOiIzb0xlTjlzIn0.qRGtH1Q-ZAViez0fTPX9fg";
    private static readonly MB_ATTRIBUTION = 'Data &copy; <a href="http://bryx.com">Bryx, Inc.</a> | Imagery from <a href="http://mapbox.com/about/maps/">MapBox</a> | Map &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    private static readonly MB_STYLE_URL = "https://api.mapbox.com/styles/v1/{user}/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}";

    static jobTypeToIcon(type: JobType): Icon {
        switch (type) {
            case JobType.fire:
                return BryxMap.fireIcon;
            case JobType.ems:
                return BryxMap.emsIcon;
            case JobType.info:
                return BryxMap.infoIcon;
            case JobType.water:
                return BryxMap.waterIcon;
            case JobType.police:
                return BryxMap.policeIcon;
        }
    }

    static hydrantIconCache: { [index: string]: DivIcon } = {};

    static hydrantIcon(hydrant: Hydrant): DivIcon {
        const cleanedSize = (hydrant.mainSize && hydrant.mainSize.toString() || "").slice(0, 2);
        const cacheKey = `hydrant:${hydrant.color}:${cleanedSize}`;
        const cachedIcon = BryxMap.hydrantIconCache[cacheKey];
        if (cachedIcon != null) {
            return cachedIcon;
        }
        const icon = new DivIcon({
            iconUrl: "/resources/assets/hydrant_pin.svg",
            iconSize: [20, 30],
            iconAnchor: [10, 30],
            className: "hydrantIcon",
            html: `<img src='${BryxApi.getHydrantSvgUrl(hydrant.color)}' height="30px" width="20px"/><span style='position: absolute; bottom: 2px; left: 0; width: 20px; text-align: center; color: white;'>${cleanedSize}</span>`,
        });
        BryxMap.hydrantIconCache[cacheKey] = icon;
        return icon;
    }

    static clientIconCache: { [index: string]: DivIcon } = {};

    static mapClientIcon(mapClient: MapClient): DivIcon {
        const cachedIcon = BryxMap.clientIconCache[mapClient.id];
        if (cachedIcon != null) {
            return cachedIcon;
        }
        const topOffset = mapClient.initials.length > 3 ? "20px" : "19px";
        const textSize = mapClient.initials.length > 3 ? "8px" : "10px";
        if (mapClient.info.type == MapClientType.user) {
            const icon = new DivIcon({
                iconAnchor: [11.5, 45],
                className: "userIcon",
                html: `<img src='/resources/assets/client_map_pins/user_pin.png' height="45px" width="24px"/><span style='position: absolute; top: ${topOffset}; left: 0; width: 24px; text-align: center; font-size: ${textSize}; font-weight: bold;'>${mapClient.initials}</span>`,
            });
            BryxMap.clientIconCache[mapClient.id] = icon;
            return icon;
        } else {
            const icon = new DivIcon({
                iconAnchor: [11.5, 45],
                className: "truckIcon",
                html: `<img src='/resources/assets/client_map_pins/truck_pin.png' height="45px" width="24px"/><span style='position: absolute; top: ${topOffset}; left: 0; width: 24px; text-align: center; font-size: ${textSize}; font-weight: bold;'>${mapClient.initials}</span>`,
            });
            BryxMap.clientIconCache[mapClient.id] = icon;
            return icon;
        }
    }

    render() {
        const mapLayerTypes: MapLayerType[] = [
            {
                i18nKey: "streets",
                id: "streets-v11",
            },
            {
                i18nKey: "satellite",
                id: "satellite-v9",
            },
            {
                i18nKey: "hybrid",
                id: "satellite-streets-v11",
            },
            {
                i18nKey: "dark",
                id: "dark-v10",
            },
        ];
        const selectedLayerId = BryxLocal.getCurrentBaseLayerId() || mapLayerTypes[
            localStorage.getItem('darkMode') == 'true' ?
                mapLayerTypes.length - 1 : 0
        ].id;

        const mapLayers = mapLayerTypes.map(type => (
            <LayersControl.BaseLayer key={`mapLayer:${type.id}`}
                                     checked={type.id == selectedLayerId}
                                     name={i18n.t(`map.${type.i18nKey}`)}>
                <TileLayer
                    url={BryxMap.MB_STYLE_URL}
                    accessToken={BryxMap.ACCESS_TOKEN}
                    user="mapbox"
                    tileSize={512}
                    zoomOffset={-1}
                    id={type.id}
                    attribution={BryxMap.MB_ATTRIBUTION}/>
            </LayersControl.BaseLayer>
        ));

        return (
            <Map
                zoom={13}
                zoomAnimation={false}
                className="bryxMap"
                {...this.props}
                onViewportChange={viewport => {
                    if (this.state.viewportStatus.key != "pending") {
                        this.setState({viewportStatus: {key: "userControlled", viewport: viewport}});
                    }
                }}
                onViewportChanged={viewport => {
                    if (this.state.viewportStatus.key == "pending") {
                        this.setState({viewportStatus: {key: "systemControlled"}});
                    }
                }}
                viewport={this.state.viewportStatus.key == "userControlled" ? this.state.viewportStatus.viewport : undefined}
                bounds={this.state.viewportStatus.key == "userControlled" ? undefined : (this.props.bounds || BryxMap.unitedStatesBounds)}
                whenReady={() => {
                    this.setState({viewportStatus: {key: "pending"}});
                }}
                ref={r => {
                    if (r != null && r.leafletElement && !r.leafletElement.hasEventListeners("baselayerchange")) {
                        r.leafletElement.addEventListener("baselayerchange", (e: any) => {
                            BryxLocal.setCurrentBaseLayerId(e.layer.options.id as string);
                        });
                    }
                }}>
                <LayersControl position="topright">
                    {mapLayers}
                </LayersControl>
                {this.state.viewportStatus.key == "userControlled" ? (
                    <LeafletControl
                        position="topleft"
                        icon="crosshairs"
                        onClick={() => {
                            if (this.state.viewportStatus.key == "userControlled") {
                                this.setState({viewportStatus: {key: "pending"}});
                            }
                        }}/>
                ) : null}
                {this.props.onClickFullscreen != null ? (
                    <LeafletControl
                        position="topleft"
                        icon="expand"
                        onClick={this.props.onClickFullscreen}/>
                ) : null}
                {this.props.children}
            </Map>
        );
    }
}
