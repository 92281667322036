import * as i18n from 'i18next';
import {Options} from "i18next";
import * as XHR from 'i18next-xhr-backend';
import * as React from "react";
import {Redirect, RouteComponentProps} from "react-router";
import {Button, Loader, Modal} from 'semantic-ui-react';
import {ZeroStateView} from "../components/views/zeroStateView";
import {config} from "../config";
import {Main} from "../index";
import {BryxApi} from "../utils/bryxApi";
import {BryxLocal} from "../utils/bryxLocal";

interface EulaPageState {
    status: { key: "ready", eula: string } |
        { key: "error", message: string } |
        { key: "loading" } |
        { key: "accepted" } |
        { key: "denied" } |
        { key: "sending" };
    i18nLoaded: boolean;
}

export class EulaPage extends React.Component<RouteComponentProps<any>, EulaPageState> {
    constructor(props: RouteComponentProps<any>, context: any) {
        super(props, context);

        if (BryxLocal.getShowEula() == true) {
            i18n
                .use(XHR)
                .init({
                    lng: BryxLocal.getItem<string>("locale") || "en-US",
                    backend: {
                        loadPath: "/resources/locales/{{lng}}.json",
                    },
                    fallbackLng: "en",
                } as Options, () => {
                    this.setState({
                        i18nLoaded: true,
                    });
                });
        }

        this.state = {
            status: {key: "loading"},
            i18nLoaded: false,
        };
    }

    componentDidMount() {
        BryxApi.getEula(result => {
            if (result.success == true) {
                this.setState({status: {key: "ready", eula: result.value.eula}});
            } else {
                config.error(`Failed to load EULA: ${result.debugMessage}`);
                this.setState({status: {key: "error", message: result.message}});
            }
        });
    }

    private acceptEula() {
        this.setState({status: {key: "sending"}});

        BryxApi.acceptEula(result => {
            if (result.success == true) {
                this.setState({status: {key: "accepted"}});
            } else {
                config.warn(`User failed to accept EULA: ${result.debugMessage}`);
                this.setState({status: {key: "error", message: result.message}});
            }
        });
    }

    render() {
        if (BryxLocal.getShowEula() != true) {
            return <Redirect to="/"/>;
        }

        let content = null;

        if (this.state.status.key == "loading" || this.state.i18nLoaded == false) {
            content = <Loader/>;
        } else if (this.state.status.key == "error") {
            content = (
                <div className="flexCenteredContainer" style={{width: "100%", height: "100%"}}>
                    <ZeroStateView header={i18n.t("eula.errorHeader")}
                                   subheader={i18n.t("eula.error")}
                                   maxWidth={"50%"}/>
                </div>
            );
        } else if (this.state.status.key == "ready") {
            content = this.state.status.eula;
        } else if (this.state.status.key == "accepted") {
            // @ts-ignore
            if (this.props.location.state && this.props.location.state.from) {
                // @ts-ignore
                return <Redirect to={this.props.location.state.from}/>;
            } else {
                return <Redirect to="/"/>;
            }
        } else if (this.state.status.key == "denied") {
            return <Redirect to="/login"/>;
        }

        return (
            <div className="eula-page">
                <Modal className="eula-page-modal" open dimmer={false}>
                    <Modal.Header>
                        {i18n.t("eula.header")}
                    </Modal.Header>
                    <Modal.Content className="modal-content">
                        <div className="content-container">
                            {content}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            positive
                            loading={this.state.status.key == "sending"}
                            onClick={() => this.acceptEula()}
                            content={i18n.t("eula.accept")}/>
                        <Button
                            negative
                            onClick={() => {
                                Main.resetManagers();
                                this.setState({status: {key: "denied"}});
                            }}
                            disabled={this.state.status.key == "sending"}
                            content={i18n.t("eula.deny")}/>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}
