import * as i18n from "i18next";
import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Button, Loader} from "semantic-ui-react";
import {ZeroStateView} from "../../components/views/zeroStateView";
import {GroupsListStatus, MessageManager, MessageManagerGroupListObserver} from "../../utils/messageManager";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";
import {UpdateManager} from "../../utils/updateManager";
import {GroupInfoView} from "./groupInfoView";
import {GroupListItem} from "./groupListItem";

interface MessagesPageState {
    groupsStatus: GroupsListStatus;
    preferences: BryxPreferences;
}

export class MessagesPage extends React.Component<RouteComponentProps<{ groupId: string | undefined }>, MessagesPageState> implements MessageManagerGroupListObserver, PreferenceManagerObserver {
    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            groupsStatus: MessageManager.shared.status,
            preferences: PreferenceManager.shared.preferences,
        };
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({preferences: newPrefs});
    }

    // MessageManagerObserver Functions

    messageManagerDidUpdateGroupsListStatus(status: GroupsListStatus): void {
        this.setState({groupsStatus: status});
    }

    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
        MessageManager.shared.registerListObserver(this);
    }

    componentWillUnmount() {
        PreferenceManager.shared.unregisterObserver(this);
        MessageManager.shared.unregisterListObserver(this);
    }

    render() {
        const {groupsStatus, preferences} = this.state;
        let groupListContent: React.ReactNode;
        if (groupsStatus.key == "loading") {
            groupListContent = (
                <div className="group-list-zero-state">
                    <Loader inline active/>
                </div>
            );
        } else if (groupsStatus.key == "failed") {
            groupListContent = (
                <div className="group-list-failed-to-load">
                    <ZeroStateView
                        header={i18n.t("messages.failedToLoad")}
                        subheader={
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <span style={{marginBottom: "10px"}}>{groupsStatus.message}</span>
                                <Button onClick={UpdateManager.shared.update}>{i18n.t("general.reload")}</Button>
                            </div>
                        }
                        icon="warning sign"
                    />
                </div>
            );
        } else if (groupsStatus.groups.length == 0) {
            groupListContent = (
                <div className="group-list-no-message-groups">
                    <ZeroStateView
                        header={i18n.t("messages.noMessageGroups.title")}
                        subheader={i18n.t("messages.noMessageGroups.body")}
                        icon="list"
                    />
                </div>
            );
        } else {
            groupListContent = groupsStatus.groups.map(g => (
                <GroupListItem
                    key={g.id}
                    group={g}
                    selected={g.id == this.props.match.params.groupId}
                    preferences={preferences}
                    onSelectGroup={group => this.props.match.params.groupId == group.id ?
                        this.props.history.push("/messaging") :
                        this.props.history.push(`/messaging/${group.id}`)}
                />
            ));
        }
        return (
            <div id="messages-page">
                <div className="group-list-content-container">
                    {groupListContent}
                </div>
                <GroupInfoView groupType="messaging" groupId={this.props.match.params.groupId || null}/>
            </div>
        );
    }
}
