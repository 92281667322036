import {Control, DomUtil} from "leaflet";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {MapControl, MapControlProps} from "react-leaflet";
import {Icon as SemanticIcon, SemanticICONS} from "semantic-ui-react";

interface LeafletControlProps extends MapControlProps {
    // Changes in these props will not be reflected in UI
    icon: SemanticICONS;
    onClick?: () => void;
}

export class LeafletControl extends MapControl<LeafletControlProps> {
    createLeafletElement(props: LeafletControlProps) {
        const control = new Control(this.props);
        const controlContent = (
            <a className="leaflet-touch"
               style={{height: "30px", width: "30px"}}
               onClick={this.props.onClick}>
                <SemanticIcon name={this.props.icon} style={{height: "100%", width: "100%", fontSize: "1.4em", margin: 0}}/>
            </a>
        );
        control.onAdd = () => {
            const div = DomUtil.create('div', 'leaflet-bar');
            ReactDOM.render(controlContent, div);
            return div;
        };
        return control;
    }
}
