import * as i18n from "i18next";
import * as React from 'react';
import {Button, Header, Modal} from 'semantic-ui-react';
import {config} from "../../config";
import {KeyValueView} from "../views/keyValueView";

interface AboutUsModalProps {
    open: boolean;
    onClose: () => void;
    onClickReleaseNotes: () => void;
}

export class AboutUsModal extends React.Component<AboutUsModalProps, {}> {
    render() {
        const versionItem = (
            <KeyValueView
                keyNode={i18n.t("about.version")}
                keyWidth="60px"
                valueNode={(
                    <div>{config.version}
                        <a
                            className="lightBackgroundLink"
                            onClick={this.props.onClickReleaseNotes}
                        >
                            {i18n.t("about.releaseNotes")}
                        </a>
                    </div>
                )}
            />
        );

        const licensesItem = (
            <KeyValueView
                keyNode={i18n.t("about.licenses")}
                keyWidth="60px"
                valueNode={(
                    <a
                        href="/licenses.txt"
                        target="_blank"
                        className="lightBackgroundLink underline"
                    >
                        {i18n.t("about.viewLicenses")}
                    </a>
                )}
            />
        );

        return (
            <Modal
                className="about-us-modal"
                size="small"
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <Header icon="file text outline" content={i18n.t("about.header")}/>

                <Modal.Content className="modal-content-container">
                    <div className="modal-content">
                        <img className="bryx-letterhead" src="/resources/assets/letterHead_compact.png"/>
                        <div className="mission-statement">{i18n.t("about.mission")}</div>
                        <div className="version-license-info">
                            {versionItem}
                            {licensesItem}
                        </div>
                    </div>
                </Modal.Content>

                <Modal.Actions>
                    <Button content={i18n.t("general.close")} onClick={this.props.onClose}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
