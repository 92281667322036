import * as React from 'react';
import {ListJob} from "../models/job";
import {JobType} from "../models/jobTypeInformation";
import {DateUtils} from "../utils/dateUtils";
import {BryxPreferences} from "../utils/preferenceManager";

interface JobListItemProps {
    job: ListJob;
    selected: boolean;
    preferences: BryxPreferences;
    width?: string;
    onClick?: (event: React.MouseEvent<any>) => void;
}

export class JobListItem extends React.Component<JobListItemProps, any> {
    constructor(props: JobListItemProps, context: any) {
        super(props, context);
    }

    render() {
        const jobListItemClassNames = ["JobListItem"];
        if (this.props.selected) {
            jobListItemClassNames.push("JobListItem_Selected");
        }
        if (this.props.onClick != null) {
            jobListItemClassNames.push("JobListItem_Clickable");
        }
        const preferences = this.props.preferences;
        const descriptionItems = [this.props.job.shortDepartment];
        if (preferences.useJobShortCode) {
            descriptionItems.push(this.props.job.typeInformation.code);
        }
        descriptionItems.push(this.props.job.synopsis);
        return (
            <div className={jobListItemClassNames.join(" ")} onClick={this.props.onClick} style={{width: this.props.width}}>
                <div className="job-list-row">
                    <img
                        className="job-type-image"
                        src={JobListItem.resourceFromJobType(this.props.job.typeInformation.type, this.props.job.hasResponded)}
                    />
                    <div className="job-item-column">
                        <span className="description-items">
                            {descriptionItems.join(" - ")}
                        </span>
                        <span className="job-item-text">
                            {this.props.job.address && this.props.job.address.originalWithLocationInfo}
                        </span>
                        <span className="job-item-text">
                            {DateUtils.formatDateTime(this.props.job.creationTime, preferences)}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    private static resourceFromJobType(type: JobType, tintedGray: boolean): string {
        return `/resources/assets/job_icons/${JobType[type]}_icon${tintedGray ? "_bw" : ""}.svg`;
    }
}
