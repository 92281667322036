import * as i18n from "i18next";
import * as React from 'react';
import {Button, Image, Loader, Menu, Message, Popup} from "semantic-ui-react";
import {DutyStatus} from "../models/auth";
import {BryxApi} from "../utils/bryxApi";

export type DutyState =
    {
        key: "initial",
    } |
    {
        key: "active",
        duty: DutyStatus,
        subState: { key: "ready" } | { key: "transition" } | { key: "failed", message: string },
    };

interface DutyMenuItemProps {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
    dutyState: DutyState;
    onUpdateDutyState: (status: DutyState) => void;
}

export class DutyMenuItem extends React.Component<DutyMenuItemProps, {}> {
    render() {
        const {dutyState} = this.props;
        let menuContent = null;
        if (dutyState.key == "initial") {
            menuContent = <Menu.Item><Loader active inline inverted size="small"/></Menu.Item>;
        } else {
            menuContent = (
                <Popup
                    id="dutyMenuItem"
                    on="click"
                    position="bottom right"
                    open={this.props.open}
                    onOpen={this.props.onOpen}
                    onClose={this.props.onClose}
                    trigger={
                        <Menu.Item>
                            <Image
                                className="duty-icon"
                                src={`/resources/assets/${dutyState.duty == DutyStatus.on ? "sun" : "moon"}.svg`}
                            />
                        </Menu.Item>
                    }>
                    <Popup.Header>{i18n.t("nav.duty.header")}</Popup.Header>
                    <Popup.Content>
                        <Button fluid
                                content={dutyState.duty == DutyStatus.on ? i18n.t("nav.duty.goOffDuty") : i18n.t("nav.duty.goOnDuty")}
                                loading={dutyState.subState.key == "transition"}
                                onMouseDown={(e: MouseEvent) => {
                                    e.stopPropagation();
                                    if (dutyState.key != "active") {
                                        return;
                                    }
                                    const newDuty = dutyState.duty == DutyStatus.on ? DutyStatus.off : DutyStatus.on;
                                    this.props.onUpdateDutyState({
                                        key: "active",
                                        duty: dutyState.duty,
                                        subState: {key: "transition"},
                                    });
                                    BryxApi.changeDuty(newDuty, result => {
                                        if (result.success == true) {
                                            this.props.onUpdateDutyState({
                                                key: "active",
                                                duty: newDuty,
                                                subState: {key: "ready"},
                                            });
                                            this.props.onClose();
                                        } else {
                                            this.props.onUpdateDutyState({
                                                key: "active",
                                                duty: dutyState.duty,
                                                subState: {key: "failed", message: result.message},
                                            });
                                        }
                                    });
                                }}/>
                        {dutyState.subState.key == "failed" ? (
                            <Message error content={dutyState.subState.message}/>
                        ) : null}
                    </Popup.Content>
                </Popup>
            );
        }
        return menuContent;
    }
}
