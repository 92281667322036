import * as i18n from "i18next";
import * as React from "react";
import {Button, Menu, Modal} from "semantic-ui-react";
import {config} from "../../config";
import {BryxLocal} from "../../utils/bryxLocal";
import {AlertsTab} from "./alertsTab";
import {DevToolsTab} from "./devToolsTab";
import {GeneralTab} from "./generalTab";
import {LocationTab} from "./locationTab";
import {PasswordTab} from "./passwordTab";

export enum SettingsTabType {
    general, alerts, location, password, devTools,
}

interface SettingsModalProps {
    open: boolean;
    currentTab: SettingsTabType;
    onTabChange: (type: SettingsTabType) => void;
    onPasswordChanged: () => void;
    onClose: () => void;
}

export class SettingsModal extends React.Component<SettingsModalProps, {}> {
    public static allTabs() {
        const tabs = [SettingsTabType.general, SettingsTabType.alerts, SettingsTabType.location, SettingsTabType.password];
        if (config.configType != "prod") {
            tabs.push(SettingsTabType.devTools);
        }
        return tabs;
    }

    render() {
        const currentTab = this.props.currentTab;
        let currentTabContent;
        switch (currentTab) {
            case SettingsTabType.general:
                currentTabContent = <GeneralTab/>;
                break;
            case SettingsTabType.alerts:
                currentTabContent = <AlertsTab/>;
                break;
            case SettingsTabType.location:
                currentTabContent = <LocationTab/>;
                break;
            case SettingsTabType.password:
                currentTabContent = <PasswordTab onPasswordChanged={this.props.onPasswordChanged}/>;
                break;
            case SettingsTabType.devTools:
                currentTabContent = <DevToolsTab/>;
                break;
        }
        return (
            <Modal
                className="settings-modal"
                open={this.props.open}
                size="small"
                onClose={this.props.onClose}>
                <Modal.Header className="settings-header bryx-text">
                    {i18n.t("settings.title")}
                    <span className="user-email bryx-subtitle">{BryxLocal.getEmail()}</span>
                </Modal.Header>
                <Modal.Content className="settings-content bryx-raised-background">
                    <Menu className="settings-tabs-menu" pointing secondary>
                        {SettingsModal.allTabs().map(t => (
                            <Menu.Item
                                key={t}
                                name={i18n.t(`settings.tabs.${SettingsTabType[t]}`)}
                                active={currentTab == t}
                                onClick={() => {
                                    this.props.onTabChange(t);
                                }}
                            />
                        ))}
                    </Menu>
                    <div className="current-tab-content">
                        {currentTabContent}
                    </div>
                </Modal.Content>
                <Modal.Actions className="bryx-raised-background">
                    <Button content={i18n.t("general.done")} onClick={this.props.onClose}/>
                </Modal.Actions>
            </Modal>
        );
    }
}
