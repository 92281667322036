import * as i18n from "i18next";
import * as React from 'react';
import Slider from "react-rangeslider";
import {Button, Input, Message, Modal} from 'semantic-ui-react';
import {config} from "../../../config";
import {
    BloodOxygen,
    BloodPressure,
    GCS,
    HeartRate,
    Patient,
    Vital,
    Vitals,
    VitalValidity,
} from "../../../models/patient";
import {BryxApi} from "../../../utils/bryxApi";
import {DateUtils} from "../../../utils/dateUtils";

export type VitalEditViewStatus =
    {
        key: "hidden",
    } |
    {
        key: "shown",
        vitalType: "bloodPressure" | "bloodOxygen" | "heartRate" | "gcs",
        patient: Patient,
    };

interface VitalEditModalProps {
    viewStatus: VitalEditViewStatus;
    onUpdate: (newVitals: Vitals) => void;
    onCancel: () => void;
}

interface VitalEditModalState {
    vital: Vital | null;
    status: { key: "ready" } | { key: "sending" } | { key: "error", message: string };
}

export class VitalEditModal extends React.Component<VitalEditModalProps, VitalEditModalState> {
    constructor(props: VitalEditModalProps, context: any) {
        super(props, context);
        this.state = {
            vital: null,
            status: {key: "ready"},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: VitalEditModalProps) {
        if (this.props.viewStatus.key == "hidden" && nextProps.viewStatus.key == "shown") {
            this.setState({vital: null, status: {key: "ready"}});
        }
    }

    private commitIfValid() {
        const {viewStatus} = this.props;
        if (viewStatus.key != "shown") {
            return;
        }

        let newVitals: Vitals;
        let validity: VitalValidity;
        switch (viewStatus.vitalType) {
            case "bloodPressure":
                const bloodPressure = this.state.vital as BloodPressure | null;
                const previousBloodPressure = viewStatus.patient.vitals.bloodPressure;
                const previousSystolic = previousBloodPressure != null ? previousBloodPressure.systolic : null;
                const previousDiastolic = previousBloodPressure != null ? previousBloodPressure.diastolic : null;
                const mergedBloodPressure = new BloodPressure(
                    bloodPressure != null && bloodPressure.systolic != null ? bloodPressure.systolic : previousSystolic,
                    bloodPressure != null && bloodPressure.diastolic != null ? bloodPressure.diastolic : previousDiastolic,
                    DateUtils.bryxNow(),
                );
                validity = mergedBloodPressure.validate();
                newVitals = new Vitals(null, mergedBloodPressure, null, null);
                break;
            case "bloodOxygen":
                const bloodOxygen = this.state.vital != null ? this.state.vital as BloodOxygen | null : viewStatus.patient.vitals.bloodOxygen;
                if (bloodOxygen == null) {
                    this.setState({
                        status: {
                            key: "error",
                            message: i18n.t("patients.vitals.validation.spO2.mustProvideAValue"),
                        },
                    });
                    return;
                }
                validity = bloodOxygen.validate();
                newVitals = new Vitals(bloodOxygen, null, null, null);
                break;
            case "heartRate":
                const heartRate = this.state.vital != null ? this.state.vital as HeartRate | null : viewStatus.patient.vitals.heartRate;
                if (heartRate == null) {
                    this.setState({
                        status: {
                            key: "error",
                            message: i18n.t("patients.vitals.validation.hr.mustProvideAValue"),
                        },
                    });
                    return;
                }
                validity = heartRate.validate();
                newVitals = new Vitals(null, null, null, heartRate);
                break;
            case "gcs":
                const gcs = (this.state.vital as GCS | null) || viewStatus.patient.vitals.gcs || GCS.maxGCS();
                validity = gcs.validate();
                newVitals = new Vitals(null, null, gcs, null);
                break;
            default:
                return;
        }

        if (validity.key == "invalid") {
            this.setState({status: {key: "error", message: i18n.t(validity.reasonKey)}});
            return;
        }

        // Vital change is valid, send it off
        this.setState({
            status: {key: "sending"},
        });

        BryxApi.updateVitals(viewStatus.patient.id, newVitals, result => {
            if (result.success == true) {
                this.setState({
                    status: {key: "ready"},
                });
                this.props.onUpdate(result.value.vitals);
            } else {
                this.setState({
                    status: {key: "error", message: result.message},
                });
                config.warn(`Failed to update patient vitals: ${result.debugMessage}`);
            }
        });
    }

    render() {
        const {viewStatus, onCancel} = this.props;
        const {vital} = this.state;
        let vitalContent;
        if (viewStatus.key == "shown") {
            switch (viewStatus.vitalType) {
                case "heartRate":
                    vitalContent = (
                        <EditHeartRateView
                            initialVital={viewStatus.patient.vitals.heartRate}
                            vital={vital as HeartRate}
                            onUpdate={newVital => this.setState({
                                vital: newVital,
                                status: {key: "ready"},
                            })}
                            onSave={() => this.commitIfValid()}/>
                    );
                    break;
                case "bloodPressure":
                    vitalContent = (
                        <EditBloodPressureView
                            initialVital={viewStatus.patient.vitals.bloodPressure}
                            vital={vital as BloodPressure}
                            onUpdate={newVital => this.setState({
                                vital: newVital,
                                status: {key: "ready"},
                            })}
                            onSave={() => this.commitIfValid()}/>
                    );
                    break;
                case "bloodOxygen":
                    vitalContent = (
                        <EditBloodOxygenView
                            initialVital={viewStatus.patient.vitals.bloodOxygen}
                            vital={vital as BloodOxygen}
                            onUpdate={newVital => this.setState({
                                vital: newVital,
                                status: {key: "ready"},
                            })}
                            onSave={() => this.commitIfValid()}/>
                    );
                    break;
                case "gcs":
                    vitalContent = (
                        <EditGCSView
                            vital={(vital as GCS | null) || viewStatus.patient.vitals.gcs || GCS.maxGCS()}
                            onUpdate={newVital => this.setState({vital: newVital, status: {key: "ready"}})}/>
                    );
            }
        }
        return (
            <Modal
                className="vital-edit-modal"
                size="mini"
                open={viewStatus.key == "shown"}
                closeOnDimmerClick={false}
                onClose={onCancel}>
                <Modal.Header>
                    {viewStatus.key == "shown" ? i18n.t(`patients.vitals.${viewStatus.vitalType}`) : null}
                </Modal.Header>
                <Modal.Content className="modal-content">
                    {vitalContent}
                    {this.state.status.key == "error" ? <Message error content={this.state.status.message}/> : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={i18n.t("general.cancel")}
                        disabled={this.state.status.key == "sending"}
                        onClick={onCancel}/>
                    <Button
                        primary
                        loading={this.state.status.key == "sending"}
                        content={i18n.t("general.save")}
                        onClick={() => this.commitIfValid()}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

interface EditHeartRateViewProps {
    initialVital: HeartRate | null;
    vital: HeartRate | null;
    onSave: () => void;
    onUpdate: (newVital: HeartRate | null) => void;
}

class EditHeartRateView extends React.Component<EditHeartRateViewProps, {}> {
    render() {
        const {initialVital, vital, onUpdate, onSave} = this.props;
        return (
            <div>
                <Input
                    autoFocus
                    placeholder={initialVital != null ? initialVital.value : i18n.t("patients.vitals.heartRate")}
                    value={vital != null ? vital.value : ""}
                    onKeyUp={(e: KeyboardEvent) => {
                        if (e.key == "Enter") {
                            onSave();
                        }
                    }}
                    onChange={(e, d) => {
                        if (d.value == "" || d.value == undefined) {
                            onUpdate(null);
                        } else {
                            const newValue = parseInt(d.value, 10);
                            if (!isNaN(newValue)) {
                                onUpdate(new HeartRate(newValue, DateUtils.bryxNow()));
                            }
                        }
                    }}/>
                <span className="heart-rate-unit">{i18n.t("patients.vitals.heartRateUnit")}</span>
            </div>
        );
    }
}

interface EditBloodPressureViewProps {
    initialVital: BloodPressure | null;
    vital: BloodPressure | null;
    onSave: () => void;
    onUpdate: (newVital: BloodPressure | null) => void;
}

class EditBloodPressureView extends React.Component<EditBloodPressureViewProps, {}> {
    render() {
        const {initialVital, vital, onSave, onUpdate} = this.props;

        return (
            <div className="blood-pressure-outer">
                <Input autoFocus
                       className="systolic"
                       placeholder={(initialVital != null && initialVital.systolic != null) ? initialVital.systolic : i18n.t("patients.vitals.systolicPlaceholder")}
                       value={(vital != null && vital.systolic != null) ? vital.systolic : ""}
                       onKeyUp={(e: KeyboardEvent) => {
                           if (e.key == "Enter") {
                               onSave();
                           }
                       }}
                       onChange={(e, d) => {
                           const newDiastolic = vital != null && vital.diastolic != null ? vital.diastolic : null;

                           if (d.value == "" || d.value == undefined) {
                               onUpdate(new BloodPressure(null, newDiastolic, DateUtils.bryxNow()));
                           } else {
                               const newValue = parseInt(d.value, 10);
                               if (!isNaN(newValue)) {
                                   onUpdate(new BloodPressure(newValue, newDiastolic, DateUtils.bryxNow()));
                               }
                           }
                       }}/>
                <div className="spacer"/>
                <span className="blood-pressure-unit">{i18n.t("patients.vitals.bloodPressureUnit")}</span>
                <Input className="diastolic" placeholder={initialVital != null ?
                    initialVital.formatDiastolic() :
                    i18n.t("patients.vitals.diastolicPlaceholder")}
                       value={(vital != null && vital.diastolic != null) ? vital.diastolic : ""}
                       onKeyUp={(e: KeyboardEvent) => {
                           if (e.key == "Enter") {
                               onSave();
                           }
                       }}
                       onChange={(e, d) => {
                           const newSystolic = vital != null && vital.systolic != null ? vital.systolic : null;

                           if (d.value == "" || d.value == undefined) {
                               onUpdate(new BloodPressure(newSystolic, null, DateUtils.bryxNow()));
                           } else {
                               const newValue = parseInt(d.value, 10);
                               if (!isNaN(newValue)) {
                                   onUpdate(new BloodPressure(newSystolic, newValue, DateUtils.bryxNow()));
                               }
                           }
                       }}/>
            </div>
        );
    }
}

interface EditBloodOxygenViewProps {
    initialVital: BloodOxygen | null;
    vital: BloodOxygen | null;
    onSave: () => void;
    onUpdate: (newVital: BloodOxygen | null) => void;
}

class EditBloodOxygenView extends React.Component<EditBloodOxygenViewProps, {}> {
    render() {
        const {initialVital, vital, onUpdate, onSave} = this.props;
        return (
            <div>
                <Input
                    autoFocus
                    placeholder={initialVital != null ? initialVital.value : i18n.t("patients.vitals.bloodOxygenPlaceholder")}
                    value={vital != null ? vital.value : ""}
                    onKeyUp={(e: KeyboardEvent) => {
                        if (e.key == "Enter") {
                            onSave();
                        }
                    }}
                    onChange={(e, d) => {
                        if (d.value == "" || d.value == undefined) {
                            onUpdate(null);
                        } else {
                            const newValue = parseInt(d.value, 10);
                            if (!isNaN(newValue)) {
                                onUpdate(new BloodOxygen(newValue, DateUtils.bryxNow()));
                            }
                        }
                    }}/>
                <span className="blood-oxygen-unit">{i18n.t("patients.vitals.bloodOxygenUnit")}</span>
            </div>
        );
    }
}

interface EditGCSViewProps {
    vital: GCS;
    onUpdate: (newVital: GCS | null) => void;
}

class EditGCSView extends React.Component<EditGCSViewProps, {}> {
    render() {
        const {vital, onUpdate} = this.props;
        return (
            <div className="edit-gcs-view">
                <div className="gcs-edit-field">
                    <span className="gcs-label">{i18n.t("patients.vitals.gcsEye")}</span>
                    <div className="gcs-slider-container">
                        <Slider
                            min={1}
                            max={4}
                            value={vital.eye}
                            onChange={newValue => {
                                onUpdate(new GCS(newValue, vital.verbal, vital.motor, DateUtils.bryxNow()));
                            }}/>
                        <span
                            className="gcs-desc-label">{`${vital.eye}: ${i18n.t(`patients.vitals.gcsValues.eye.${vital.eye}`)}`}</span>
                    </div>
                </div>
                <div className="gcs-edit-field">
                    <span className="gcs-label">{i18n.t("patients.vitals.gcsVerbal")}</span>
                    <div className="gcs-slider-container">
                        <Slider
                            min={1}
                            max={5}
                            value={vital.verbal}
                            onChange={newValue => {
                                onUpdate(new GCS(vital.eye, newValue, vital.motor, DateUtils.bryxNow()));
                            }}/>
                        <span
                            className="gcs-desc-label">{`${vital.verbal}: ${i18n.t(`patients.vitals.gcsValues.verbal.${vital.verbal}`)}`}</span>
                    </div>
                </div>
                <div className="gcs-edit-field">
                    <span className="gcs-label">{i18n.t("patients.vitals.gcsMotor")}</span>
                    <div className="gcs-slider-container">
                        <Slider
                            min={1}
                            max={6}
                            value={vital.motor}
                            onChange={newValue => {
                                onUpdate(new GCS(vital.eye, vital.verbal, newValue, DateUtils.bryxNow()));
                            }}/>
                        <span
                            className="gcs-desc-label">{`${vital.motor}: ${i18n.t(`patients.vitals.gcsValues.motor.${vital.motor}`)}`}</span>
                    </div>
                </div>
                <div style={{width: "100%", display: "flex", marginTop: "20px"}}>
                    <Button content={i18n.t("patients.vitals.gcsMin")}
                            onClick={() =>
                                onUpdate(GCS.minGCS())
                            }
                            style={{flex: 1, marginRight: "5px"}}/>
                    <Button content={i18n.t("patients.vitals.gcsMax")}
                            onClick={() =>
                                onUpdate(GCS.maxGCS())
                            }
                            style={{flex: 1, marginLeft: "5px"}}/>
                </div>
            </div>
        );
    }
}
