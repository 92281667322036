import {config} from "../config";
import {Group} from "../models/group";
import {GroupUpdate} from "../models/groupUpdate";
import {BryxApi} from "./bryxApi";

export interface MessageManagerGroupListObserver {
    messageManagerDidUpdateGroupsListStatus(status: GroupsListStatus): void;
}

export interface MessageManagerActiveGroupObserver {
    messageManagerDidReceiveActiveGroup(group: Group): void;
    messageManagerDidUpdateActiveGroup(group: Group, update: GroupUpdate): void;
    messageManagerDidLoadOldMessagesForActiveGroup(group: Group): void;
    messageManagerDidReachEndOfActiveGroupMessages(group: Group): void;
    messageManagerDidFailToLoadActiveGroup(errorMessage: string): void;
}

export interface ActiveGroupInfo {
    id: string;
    cachedGroup: Group | null;
}

export type GroupsListStatus = {key: "loading"} | {key: "active", groups: Group[]} | {key: "failed", message: string};

export class MessageManager {
    static shared = new MessageManager();

    status: GroupsListStatus = {key: "loading"};
    activeGroup: ActiveGroupInfo | null = null;

    private static readonly groupsListSubscriptionKey = "messageManager-list";
    private static specificGroupSubscriptionKey(groupId: string): string {
        return `messageManager-${groupId}`;
    }

    private listObservers: MessageManagerGroupListObserver[] = [];
    private activeGroupObservers: MessageManagerActiveGroupObserver[] = [];

    startMonitoringGroups() {
        BryxApi.subscribeToGroupsList(MessageManager.groupsListSubscriptionKey, result => {
            if (result.success == true) {
                switch (result.value.key) {
                    case "replace":
                        this.updateStatus({
                            key: "active",
                            groups: result.value.groups.sort(Group.compare),
                        });
                        break;
                    case "addedGroup":
                        const newGroup = result.value.group;
                        if (this.status.key == "active") {
                            this.status.groups.push(newGroup);
                            this.status.groups.sort(Group.compare);
                            this.listObservers.forEach(o => o.messageManagerDidUpdateGroupsListStatus(this.status));
                        } else {
                            config.error(`Failed to add new group, status = ${this.status.key}`);
                        }
                        break;
                    case "removedGroup":
                        const removedGroupId = result.value.groupId;
                        if (this.status.key == "active") {
                            this.status.groups = this.status.groups.filter(g => g.id != removedGroupId);
                            this.listObservers.forEach(o => o.messageManagerDidUpdateGroupsListStatus(this.status));
                        } else {
                            config.error(`Failed to remove group, status = ${this.status.key}`);
                        }
                        break;
                    case "message":
                        const {groupId, message} = result.value;
                        if (this.status.key == "active") {
                            const matchedGroups = this.status.groups.filter(g => g.id == groupId);
                            if (matchedGroups.length > 0) {
                                const group = matchedGroups[0];
                                group.messages.push(message);
                                if (message.timeSent.getTime() > group.lastUpdated.getTime()) {
                                    group.lastUpdated = message.timeSent;
                                }
                            }
                            this.status.groups.sort(Group.compare);
                            this.listObservers.forEach(o => o.messageManagerDidUpdateGroupsListStatus(this.status));
                        } else {
                            config.error(`Failed to add message to group in groups list, status = ${this.status.key}`);
                        }
                        break;
                }
            } else {
                config.warn(`Failed to load groups: ${result.debugMessage}`);
                this.updateStatus({
                    key: "failed",
                    message: result.message,
                });
            }
        });
    }

    private updateStatus(status: GroupsListStatus) {
        this.status = status;
        this.listObservers.forEach(o => o.messageManagerDidUpdateGroupsListStatus(status));
    }

    setActiveGroup(groupId: string) {
        if (this.activeGroup != null) {
            return;
        }
        this.activeGroup = {
            id: groupId,
            cachedGroup: null,
        };

        BryxApi.subscribeToGroup(MessageManager.specificGroupSubscriptionKey(groupId), groupId, result => {
            if (result.success == true) {
                const activeGroup = this.activeGroup;
                const cachedGroup = activeGroup != null ? activeGroup.cachedGroup : null;
                switch (result.value.key) {
                    case "replace":
                        config.debug(`Processing 'replace' for group@${groupId}`);
                        const fullGroup = result.value.group;
                        if (cachedGroup != null) {
                            cachedGroup.updateWithGroup(fullGroup);
                        } else if (activeGroup != null) {
                            activeGroup.cachedGroup = fullGroup;
                        } else {
                            config.warn(`Trying to replace a cached group@${groupId} which has been removed`);
                        }
                        this.activeGroupObservers.forEach(o => o.messageManagerDidReceiveActiveGroup(fullGroup));
                        break;
                    case "message":
                        config.debug(`Processing 'message' for group@${groupId}`);
                        if (cachedGroup != null) {
                            cachedGroup.addMessages([result.value.message]);
                            this.activeGroupObservers.forEach(o => o.messageManagerDidUpdateActiveGroup(cachedGroup, result.value));
                        }
                        break;
                    case "member":
                        config.debug(`Processing 'member' for group@${groupId}`);
                        if (cachedGroup != null) {
                            cachedGroup.addMember(result.value.member);
                            this.activeGroupObservers.forEach(o => o.messageManagerDidUpdateActiveGroup(cachedGroup, result.value));
                        }
                        break;
                }
            } else {
                this.activeGroupObservers.forEach(o => o.messageManagerDidFailToLoadActiveGroup(result.message));
            }
        });
    }

    clearActiveGroup(groupId: string) {
        if (this.activeGroup == null || this.activeGroup.id != groupId) {
            config.warn("Could not clear active group; not set or ID did not match");
            return;
        }
        BryxApi.unsubscribe(MessageManager.specificGroupSubscriptionKey(groupId));
        this.activeGroup = null;
    }

    loadMoreMessagesForActiveGroup(groupId: string) {
        if (this.activeGroup == null || this.activeGroup.id != groupId || this.activeGroup.cachedGroup == null) {
            config.warn("Could not load more messages; not set or ID did not match");
            return;
        }
        const messages = this.activeGroup.cachedGroup.messages;
        if (messages.length == 0) {
            config.error("Could not load more messages; no messages in group");
            return;
        }
        BryxApi.loadOldMessages(groupId, messages[0].timeSent, result => {
            if (result.success == true) {
                const activeGroup = this.activeGroup != null ? this.activeGroup.cachedGroup : null;
                if (activeGroup == null) {
                    return;
                }
                activeGroup.addMessages(result.value);
                this.activeGroupObservers.forEach(o => o.messageManagerDidLoadOldMessagesForActiveGroup(activeGroup));
                if (result.value.length < 30) {
                    this.activeGroupObservers.forEach(o => o.messageManagerDidReachEndOfActiveGroupMessages(activeGroup));
                }
            } else {
                config.warn(`Failed to load old messages for group@${groupId}: ${result.debugMessage}`);
            }
        });
    }

    stopMonitoringGroups() {
        BryxApi.unsubscribe(MessageManager.groupsListSubscriptionKey);
    }

    reset() {
        this.updateStatus({key: "loading"});
        this.stopMonitoringGroups();
    }

    // MessageManagerObserver Functions

    public registerListObserver(observer: MessageManagerGroupListObserver) {
        if (this.listObservers.filter(o => o === observer).length == 0) {
            this.listObservers.push(observer);
        }
    }

    public unregisterListObserver(observer: MessageManagerGroupListObserver) {
        const observerIndex = this.listObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.listObservers.splice(observerIndex, 1);
        }
    }

    public registerActiveGroupObserver(observer: MessageManagerActiveGroupObserver) {
        if (this.activeGroupObservers.filter(o => o === observer).length == 0) {
            this.activeGroupObservers.push(observer);
        }
    }

    public unregisterActiveGroupObserver(observer: MessageManagerActiveGroupObserver) {
        const observerIndex = this.activeGroupObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.activeGroupObservers.splice(observerIndex, 1);
        }
    }
}
