import * as React from "react";
import {Card, Icon} from 'semantic-ui-react';
import {PatientCreationModal} from "../../components/modals/patientCreationModal";
import {PatientItemView} from "../../components/views/patientItemView";
import {FullJob} from "../../models/job";
import {Patient} from "../../models/patient";

interface JobPatientsProps {
    patients: Patient[];
    job: FullJob;
    onSelectPatient: (patientId: string, initialCreationPrompts: string[] | null) => void;
}

interface JobPatientsState {
    isCreating: boolean;
}

export class JobPatientsTab extends React.Component<JobPatientsProps, JobPatientsState> {
    constructor(props: JobPatientsProps, context: any) {
        super(props, context);
        this.state = {
            isCreating: false,
        };
    }

    render() {
        const {patients} = this.props;
        return (
            <div className="job-patients-tab">
                <Card.Group>
                    {patients.map(p => (
                        <Card
                            key={p.id}
                            className="patient-card"
                            onClick={() => this.props.onSelectPatient(p.id, null)}
                        >
                            <PatientItemView patient={p}/>
                        </Card>
                    ))}
                    <Card className="add-patient-button-container" onClick={() => this.setState({isCreating: true})}>
                        <Icon className="add-patient-button" name="plus" size="huge" />
                    </Card>
                </Card.Group>
                <PatientCreationModal
                    open={this.state.isCreating}
                    job={this.props.job}
                    onCancel={() => this.setState({isCreating: false})}
                    onCreatePatient={(newPatient, prompts) => {
                        this.setState({isCreating: false}, () => this.props.onSelectPatient(newPatient.id, prompts));
                    }}
                />
            </div>
        );
    }
}
