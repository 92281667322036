import * as i18n from "i18next";
import * as $ from "jquery";
import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Button} from "semantic-ui-react";
import {FullJob} from "../../models/job";
import {
    JobListType,
    JobManager,
    JobManagerActiveJobObserver,
    JobManagerListObserver,
    JobsListsStatus,
} from "../../utils/jobManager";
import {JobInfoView} from "./jobInfoView";
import {JobsListView} from "./jobsListView";

interface JobsPageState {
    listsStatus: JobsListsStatus;
    selectedList: JobListType | null;
}

export interface JobsPageMatchParams {
    jobId: string | undefined;
    tabId: string | undefined;
    contentId: string | undefined;
}

export class JobsPage extends React.Component<RouteComponentProps<JobsPageMatchParams>, JobsPageState> implements JobManagerListObserver, JobManagerActiveJobObserver {
    private openListRef: Element | null = null;
    private closedListRef: Element | null = null;

    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            selectedList: props.match.params.jobId == null ? JobListType.open : null,
            listsStatus: JobManager.shared.jobsListsStatus,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: RouteComponentProps<{ jobId: string | undefined }>, nextContext: any) {
        if (nextProps.match.params.jobId == null && this.state.selectedList == null) {
            this.setState({
                selectedList: JobListType.open,
            });
        }
    }

    componentDidMount() {
        JobManager.shared.registerListObserver(this);
        JobManager.shared.registerActiveJobObserver(this);
    }

    componentWillUnmount() {
        JobManager.shared.unregisterActiveJobObserver(this);
        JobManager.shared.unregisterListObserver(this);
    }

    // JobManagerObserver Functions

    jobManagerDidUpdateJobsListsStatus(status: JobsListsStatus): void {
        this.setState({listsStatus: status});
    }

    jobManagerDidReceiveActiveJob(job: FullJob): void {
        this.setState({selectedList: job.isOpen ? JobListType.open : JobListType.closed});
    }

    jobManagerDidUpdateActiveJobSupplementals(activeJob: FullJob): void { /* unused */
    }

    jobManagerDidUpdateActiveJobResponders(activeJob: FullJob): void { /* unused */
    }

    jobManagerDidUpdateActiveJobHydrants(activeJob: FullJob): void { /* unused */
    }

    jobManagerDidUpdateActiveJobAssignments(activeJob: FullJob): void { /* unused */
    }

    jobManagerDidUpdateActiveJobPatients(activeJob: FullJob): void { /* unused */
    }

    jobManagerDidFailToLoadActiveJob(errorMessage: string): void { /* unused */
    }

    render() {
        return (
            <div id="jobs-page">
                <div className="jobs-page-container-left">
                    <Button.Group className="job-page-toggle-buttons-container">
                        <Button
                            toggle
                            className="JobListToggleBtn"
                            active={this.state.selectedList == JobListType.open}
                            onClick={() => {
                                if (this.state.selectedList == JobListType.open) {
                                    const openListRef = this.openListRef;
                                    if (openListRef != null) {
                                        $(openListRef).animate({scrollTop: 0});
                                    }
                                } else {
                                    this.setState({selectedList: JobListType.open}, () => this.props.history.push("/jobs"));
                                }
                            }}>
                            {i18n.t("jobs.jobsList.openTab")}
                        </Button>
                        <Button toggle
                                className="JobListToggleBtn"
                                active={this.state.selectedList == JobListType.closed}
                                onClick={() => {
                                    if (this.state.selectedList == JobListType.closed) {
                                        const closedListRef = this.closedListRef;
                                        if (closedListRef != null) {
                                            $(closedListRef).animate({scrollTop: 0});
                                        }
                                    } else {
                                        this.setState({selectedList: JobListType.closed}, () => this.props.history.push("/jobs"));
                                    }
                                }}>
                            {i18n.t("jobs.jobsList.closedTab")}
                        </Button>
                    </Button.Group>
                    <JobsListView
                        visible={this.state.selectedList == JobListType.open}
                        listType={JobListType.open}
                        listStatus={JobManager.getListStatus(JobListType.open, this.state.listsStatus)}
                        selectedJobId={this.props.match.params.jobId || null}
                        onSelectJob={job => {
                            if (this.props.match.params.jobId != job.id) {
                                this.props.history.push(`/jobs/${job.id}`);
                            }
                        }}
                        onListRef={ref => this.openListRef = ref}
                    />
                    <JobsListView
                        visible={this.state.selectedList == JobListType.closed}
                        listType={JobListType.closed}
                        listStatus={JobManager.getListStatus(JobListType.closed, this.state.listsStatus)}
                        selectedJobId={this.props.match.params.jobId || null}
                        onSelectJob={job => {
                            if (this.props.match.params.jobId != job.id) {
                                this.props.history.push(`/jobs/${job.id}`);
                            }
                        }}
                        onListRef={ref => this.closedListRef = ref}
                    />
                </div>
                <JobInfoView {...this.props}/>
            </div>
        );
    }
}
