import {config} from "../config";
import {ApiResult, BryxApi} from "../utils/bryxApi";
import {BryxLocal} from "../utils/bryxLocal";
import {ParseResult, ParseUtils} from "../utils/cerealParse";
import {DateUtils} from "../utils/dateUtils";
import {GroupMember} from "./groupMember";
import {Message, MessageContent, MessageContentType, MessageParameter, MessageStatusType} from "./message";

export enum GroupType {
    patient, department, dispatch, hospital, chat,
}

export class Group {
    private constructor(
        public id: string,
        public name: string,
        public type: GroupType,
        public lastUpdated: Date,
        public canManage: boolean,
        public canSend: boolean,
        public members: GroupMember[],
        public messages: Message[],
        public sendingMessages: Message[],
    ) {}

    get latestMessage(): Message | null {
        return this.messages.slice(-1)[0] || null;
    }

    static parse(o: any): ParseResult<Group> {
        try {
            return ParseUtils.parseSuccess(new Group(
                ParseUtils.getString(o, "id"),
                ParseUtils.getString(o, "name"),
                ParseUtils.getEnum(o, "type", GroupType),
                ParseUtils.getUNIXTimestampDate(o["lastUpdated"], "sec"),
                ParseUtils.getBoolean(o, "canManage"),
                ParseUtils.getBoolean(o, "canSend"),
                // TODO: Group model should be separated into ListGroup and FullGroup; `members` should only exist in full model.
                ParseUtils.getArrayOfSubobjectsOrNull(o, "members", GroupMember.parse, "warn") || [],
                ParseUtils.getArrayOfSubobjects(o, "messages", Message.parse, "warn").sort(Message.compare),
                [],
            ));
        } catch (e) {
            return ParseUtils.parseFailure<Group>(`Invalid Group Model: ${e.message}`);
        }
    }

    public messageIsRead(message: Message): boolean {
        return this.members.filter(m => m.marksRead && !m.isMe && m.readUntil >= message.timeSent).length > 0;
    }

    public updateWithGroup(group: Group) {
        this.lastUpdated = group.lastUpdated;
        this.members = group.members;
        this.messages = group.messages;
    }

    public addMessages(newMessages: Message[]) {
        const messageIds = this.messages.map(m => m.id);
        newMessages.forEach(message => {
            const existingIndex = messageIds.indexOf(message.id);
            if (existingIndex != -1) {
                this.messages[existingIndex] = message;
            } else {
                this.messages.push(message);
            }
        });
        this.messages.sort(Message.compare);
    }

    public addMember(member: GroupMember) {
        const existingIndex = this.members.map(m => m.id).indexOf(member.id);
        if (existingIndex != -1) {
            this.members[existingIndex] = member;
        } else {
            this.members.push(member);
        }
    }

    public sendMessage(parameter: MessageParameter, callback: (result: ApiResult<Message>) => void) {
        const now = DateUtils.bryxNow();
        let content: MessageContent;
        switch (parameter.type) {
            case MessageContentType.text:
                content = {type: MessageContentType.text, text: parameter.text};
                break;
            case MessageContentType.image:
                content = {type: MessageContentType.image, imageId: parameter.image, width: 200, height: 200};
                break;
            default:
                throw Error("Unsupported sent message type");
        }
        const tempId = `sending-${now.getTime()}`;
        this.sendingMessages.push(new Message(tempId, now, {id: "me", name: BryxLocal.getName() || "", isMe: true}, content, {key: MessageStatusType.sending}));
        this.sendingMessages.sort(Message.compare);
        BryxApi.sendMessage(parameter, this.id, result => {
            if (result.success == true) {
                this.sendingMessages = this.sendingMessages.filter(m => m.id != tempId);
                this.addMessages([result.value]);
            } else {
                const sendingMessages = this.sendingMessages.filter(m => m.id == tempId);
                if (sendingMessages.length > 0) {
                    const sendingMessage = sendingMessages[0];
                    sendingMessage.status = {key: MessageStatusType.failed, error: result.message};
                }
            }
            callback(result);
        });
    }

    public resendMessage(message: Message, callback: (result: ApiResult<Message>) => void) {
        if (message.status.key != MessageStatusType.failed || this.sendingMessages.filter(m => m.id == message.id).length == 0) {
            config.error("Could not re-send message that has not failed");
            return;
        }
        let parameter: MessageParameter;
        switch (message.content.type) {
            case MessageContentType.text:
                parameter = {type: MessageContentType.text, text: message.content.text};
                break;
            default:
                throw Error("Unsupported re-send message type");
        }
        this.sendingMessages = this.sendingMessages.filter(m => m.id != message.id);
        this.sendMessage(parameter, callback);
    }

    static compare(g1: Group, g2: Group): number {
        return g2.lastUpdated.getTime() - g1.lastUpdated.getTime();
    }

}
