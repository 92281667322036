import {LineString, Point} from "geojson";
import * as i18n from "i18next";
import {v4 as uuidgen} from "uuid";
import {config} from "../config";
import {Auth as AuthModel, DutyStatus, LocationPrivacy} from "../models/auth";
import {ApiError} from "../models/bryxTypes";
import {Eula} from "../models/eula";
import {GroupListUpdate, parseGroupListUpdate} from "../models/groupListUpdate";
import {GroupUpdate, parseGroupUpdate} from "../models/groupUpdate";
import {Injury} from "../models/injury";
import {ListJob} from "../models/job";
import {JobsListUpdate, parseJobsListUpdate} from "../models/jobsListUpdate";
import {MapClient} from "../models/mapClient";
import {Message, MessageContentType, MessageParameter} from "../models/message";
import {NotificationUpdate, parseNotificationUpdate} from "../models/notificationUpdate";
import {Patient, Sex, Vitals} from "../models/patient";
import {parsePatientUpdate, PatientUpdate} from "../models/patientUpdate";
import {ResponseOption} from "../models/responder";
import {ServerTime} from "../models/serverTime";
import {AlertSetting, AlertSettingType, Filter, Shift, ShiftStatus} from "../models/shift";
import {SiteSurvey} from "../models/siteSurvey";
import {parseSpecificJobUpdate, SpecificJobUpdate} from "../models/specificJobUpdate";
import {BryxLocal} from "./bryxLocal";
import {BryxWebSocket} from "./bryxWebSocket";
import {ParseResult, ParseUtils} from './cerealParse';
import {DateUtils} from "./dateUtils";
import {DeviceUtils} from "./deviceUtils";
import {JobListType} from "./jobManager";
import {ClientConfig, HttpClient, HttpRequest, HttpResponse, ResponseStatus, UrlParams} from "./spoonClient";
import {SupportUtils} from "./supportUtils";

export type ApiResult<T> =
    { success: true, value: T } |
    { success: false, message: string, debugMessage: string | null };

export function apiSuccess<T>(value: T): ApiResult<T> {
    return {success: true, value: value};
}

export function apiFailure<T>(message: string | null, debugMessage: string | null): ApiResult<T> {
    return {success: false, message: message || i18n.t("general.genericError"), debugMessage: debugMessage};
}

function nullParser(o: any): ParseResult<null> {
    return ParseUtils.parseSuccess(null);
}

function apiArrayResultFromParse<T>(response: HttpResponse, parseFunction: (o: any) => ParseResult<T>, failBehavior: "ignore" | "warn" | "throw"): ApiResult<T[]> {
    if (response.status == ResponseStatus.Success) {
        const itemsObject = {items: response.responseJson};
        return apiSuccess(ParseUtils.getArrayOfSubobjects(itemsObject, 'items', parseFunction, failBehavior));
    } else {
        return apiFailureWithResponse<T[]>(response);
    }
}

function apiResultFromParse<T>(response: HttpResponse, parseFunction: (o: any) => ParseResult<T>): ApiResult<T> {
    if (response.status == ResponseStatus.Success) {
        const parseResult = parseFunction(response.responseJson);
        if (parseResult.success == true) {
            return apiSuccess(parseResult.value);
        } else {
            return apiFailure<T>(null, parseResult.justification);
        }
    } else {
        return apiFailureWithResponse<T>(response);
    }
}

function apiFailureWithResponse<T>(response: HttpResponse): ApiResult<T> {
    if (response.status == ResponseStatus.ConnectionFailure) {
        return apiFailure<T>(i18n.t("general.connectionFailure"), "Connection failure");
    }
    const errorResult = ApiError.parse(response.responseJson);
    if (errorResult.success == true) {
        return apiFailure<T>(errorResult.value.message, errorResult.value.realCause || errorResult.value.message);
    } else {
        return apiFailure<T>(null, errorResult.justification);
    }
}

export class BryxApi {
    private static readonly apiRoot: string = `https://${config.baseUrl}/api/2.2`;
    public static readonly joinUrl: string = `https://${config.baseUrl}/agencies/join`;
    public static readonly wsUrl: string = `wss://${config.baseUrl}/api/2.2`;
    public static onUnauthenticated: () => void;

    private static http: HttpClient = (() => {
        const httpClientConfig: ClientConfig = {
            baseUrl: BryxApi.apiRoot,
            transformRequest: (request: HttpRequest) => {
                const headers = request.headers || {};

                const currentApiKey = BryxLocal.getApiKey();
                if (currentApiKey != null) {
                    headers["X-API-KEY"] = currentApiKey;
                }

                if (request.body != null) {
                    headers["content-type"] = "application/json";
                }

                request.headers = headers;
                return request;
            },
        };
        return new HttpClient(httpClientConfig);
    })();

    private static unauthenticatedCallback(callback: (request: HttpRequest, response: HttpResponse) => void): (request: HttpRequest, response: HttpResponse) => void {
        return (request: HttpRequest, response: HttpResponse) => {
            if (response.status == ResponseStatus.Unauthorized && BryxApi.onUnauthenticated != null) {
                BryxApi.onUnauthenticated();
            }
            callback(request, response);
        };
    }

    public static signOut(callback: (result: ApiResult<null>) => void): void {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            if (response.status == ResponseStatus.Success) {
                callback(apiSuccess(null));
            } else {
                callback(apiFailureWithResponse<null>(response));
            }
        };

        BryxApi.http.del("/authorization/", null, wrappedCallback);
    }

    public static signOutApparatus(password: string, callback: (result: ApiResult<null>) => void): void {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            if (response.status == ResponseStatus.Success) {
                callback(apiSuccess(null));
            } else {
                callback(apiFailureWithResponse<null>(response));
            }
        };

        const urlParams: UrlParams = {
            password: password,
        };

        BryxApi.http.del("/authorization/", urlParams, wrappedCallback);
    }

    private static authCallback(request: HttpRequest, response: HttpResponse, email: string | null, callback: (result: ApiResult<AuthModel>) => void): void {
        if (response.status == ResponseStatus.Success) {
            const parseResult = AuthModel.parse(response.responseJson);
            if (parseResult.success == true) {
                BryxLocal.initializeFromAuthModel(parseResult.value);
                callback(apiSuccess(parseResult.value));
            } else {
                callback(apiFailure<AuthModel>(null, parseResult.justification));
            }
        } else {
            callback(apiFailureWithResponse<AuthModel>(response));
        }
    }

    public static updateOffset(callback: (result: ApiResult<null>) => void): void {
        BryxApi.http.get("/ts", null, (request, response) => {
            const apiResult = apiResultFromParse(response, ServerTime.parse);
            if (apiResult.success == true) {
                DateUtils.setOffset(apiResult.value.time.getTime() - new Date().getTime());
                callback(apiSuccess(null));
            } else {
                config.warn(`Failed to update time offset: ${apiResult.debugMessage}`);
                callback(apiResult);
            }
        });
    }

    public static changePassword(currentPass: string, newPass: string, callback: (result: ApiResult<null>) => void): void {
        const body = {
            oldPassword: currentPass,
            newPassword: newPass,
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put("/users/me/password", null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static getEula(callback: (result: ApiResult<Eula>) => void): void {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, Eula.parse));
        };

        BryxApi.http.get("/eula", null, wrappedCallback);
    }

    public static acceptEula(callback: (result: ApiResult<null>) => void): void {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put("/users/me/eula", null, null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static signIn(email: string, password: string, token: string | null, callback: (result: ApiResult<AuthModel>) => void): void {
        const deviceInfo = DeviceUtils.deviceInfo;
        const hardwareInfo = {
            manufacturer: deviceInfo.manufacturer || null,
            make: deviceInfo.name || null,
            model: deviceInfo.product || null,
            osName: deviceInfo.os.family || null,
            osVersion: deviceInfo.os.version || null,
        };

        const authBody = {
            credentials: token ? {
                method: 'token',
                token,
            } : {
                method: 'email',
                email,
                password,
            },
            hardwareId: BryxLocal.getDeviceId(),
            hardwareInfo,
            service: {
                type: 'bryx911',
                deviceName: DeviceUtils.deviceName,
                features: {
                    passcode: false,
                },
                engine: 'ws',
                canUseForLocation: true,
                // Push token is only generated on sign in and doesn't need to be recorded.
                pushToken: uuidgen(),
            },
            serviceVersion: config.version,
        };

        BryxApi.http.post("/session/", null, authBody, (request: HttpRequest, response: HttpResponse) => {
            BryxApi.authCallback(request, response, email, callback);
        });
    }

    public static session(callback: (result: ApiResult<AuthModel>) => void): void {
        const deviceInfo = DeviceUtils.deviceInfo;
        const hardwareInfo = {
            manufacturer: deviceInfo.manufacturer || null,
            make: deviceInfo.name || null,
            model: deviceInfo.product || null,
            osName: deviceInfo.os.family || null,
            osVersion: deviceInfo.os.version || null,
        };

        const authBody = {
            serviceVersion: config.version,
            features: {
                passcode: false,
            },
            hardwareInfo,
        };

        BryxApi.http.put("/session/", null, authBody, BryxApi.unauthenticatedCallback((request: HttpRequest, response: HttpResponse) => {
            BryxApi.authCallback(request, response, null, result => {
                BryxApi.updateOffset(offsetResult => {
                    callback(result);
                });
            });
        }));
    }

    // Jobs

    public static loadJobs(after: Date, limit: number, type: JobListType, callback: (result: ApiResult<ListJob[]>) => void) {
        const params: any = {
            limit: limit,
            archived: type == JobListType.closed,
            model: "list",
        };

        if (after != null) {
            params.time = Math.floor(after.getTime() / 1000);
        }

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiArrayResultFromParse(response, ListJob.parse, "warn"));
        };

        BryxApi.http.get("/jobs", params, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static respondToJob(jobId: string, responseOption: ResponseOption, callback: (result: ApiResult<null>) => void): void {
        // TODO: Use responseId when available
        const requestBody = {
            response: responseOption.text,
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put(`/jobs/${jobId}/responders`, null, requestBody, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static loadHistoricalJobs(jobId: string, callback: (result: ApiResult<ListJob[]>) => void): void {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiArrayResultFromParse(response, ListJob.parse, "warn"));
        };
        BryxApi.http.get(`/jobs/${jobId}/historical`, null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static loadSiteSurvey(jobId: string, callback: (result: ApiResult<SiteSurvey | null>) => void): void {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            if (response.status == ResponseStatus.Success && response.responseJson == undefined) {
                callback(apiResultFromParse(response, nullParser));
            } else {
                callback(apiResultFromParse(response, SiteSurvey.parse));
            }
        };

        BryxApi.http.get(`/jobs/${jobId}/legacy-site-survey`, null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static sendSupportTicket(from: string, subject: string | null, type: string, body: string, image: File | null, callback: (result: ApiResult<null>) => void) {
        const sendRequest = (imageData: { fileName: string, base64Data: string, contentType: string } | null) => {
            const attachments = [
                {
                    contentType: "text/plain",
                    fileName: "localStorage.txt",
                    data: SupportUtils.bryxItemsAttachment(),
                },
                {
                    contentType: "text/plain",
                    fileName: "logs.txt",
                    data: SupportUtils.logsAttachment(),
                },
                {
                    contentType: "text/plain",
                    fileName: "deviceInfo.txt",
                    data: SupportUtils.deviceInfoAttachment(),
                },
            ];
            if (imageData != null) {
                attachments.push({
                    fileName: imageData.fileName,
                    contentType: imageData.contentType,
                    data: imageData.base64Data,
                });
            }
            const requestBody = {
                body: body,
                email: from,
                subject: subject,
                type: type,
                platform: "universal",
                attachments: attachments,
            };

            const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
                callback(apiResultFromParse(response, nullParser));
            };

            BryxApi.http.post("/support", null, requestBody, BryxApi.unauthenticatedCallback(wrappedCallback));
        };

        if (image != null) {
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = () => {
                const splitParts = (typeof reader.result === "string") ? reader.result.split(";base64,") : [];
                const contentType = splitParts[0].replace("data:", "");
                const imageBase64Data = splitParts[1];
                sendRequest({fileName: image.name, base64Data: imageBase64Data, contentType: contentType});
            };
            reader.onerror = () => callback(apiFailure(null, `Unable to load image file: ${reader.error}`));
        } else {
            sendRequest(null);
        }
    }

    public static forgotPassword(email: string, captchaResponse: string, callback: (result: ApiResult<null>) => void) {
        const body = {
            email: email,
            validationType: "recaptchaV2",
            response: captchaResponse,
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.post("/password-reset", null, body, wrappedCallback);
    }

    // Location

    public static setLocationPrivacy(privacy: LocationPrivacy, callback: (result: ApiResult<null>) => void) {
        const body = {
            locationPrivacy: LocationPrivacy[privacy],
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put("/users/me/location/privacy", null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static setDeviceForLocation(deviceId: string, callback: (result: ApiResult<null>) => void) {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put(`/devices/${deviceId}/location`, null, null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static updateLocation(position: Position, callback: (result: ApiResult<null>) => void) {
        const body = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            heading: position.coords.heading,
            speed: position.coords.speed,
            altitude: position.coords.altitude,
        };
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put("/users/me/location", null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static getClientLocation(callback: (result: ApiResult<Point>) => void) {

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, (o: any) => {
                try {
                    return ParseUtils.parseSuccess(o.coordinates);
                } catch (e) {
                    return ParseUtils.parseFailure<Point>(`No location set!`);
                }
            }));
        };

        BryxApi.http.get("/clients/me/location", null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    // Routes

    public static getRoute(startLocation: Point, endLocation: Point, callback: (result: ApiResult<LineString>) => void): void {
        const params = {
            startLong: startLocation.coordinates[0],
            startLat: startLocation.coordinates[1],
            endLong: endLocation.coordinates[0],
            endLat: endLocation.coordinates[1],
        };
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, (o: any) => {
                try {
                    const geometry = o.routes[0].geometry;
                    if (geometry == null) {
                        return ParseUtils.parseFailure("Invalid LineString Model: Missing `geometry`");
                    }
                    return ParseUtils.parseSuccess(geometry as LineString);
                } catch (e) {
                    return ParseUtils.parseFailure<LineString>(`Invalid GeoJSONLineString Model: ${e.message}`);
                }
            }));
        };
        BryxApi.http.get("/osrm", params, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    // Shifts

    public static setAllowNotifications(allowNotifications: boolean, callback: (result: ApiResult<null>) => void): void {
        const body = {
            receivePush: allowNotifications,
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.put("/devices", null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static changeDuty(duty: DutyStatus, callback: (result: ApiResult<null>) => void) {
        const body = {
            receiveNotifications: duty == DutyStatus.on,
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put("/users/me", null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static getShifts(callback: (result: ApiResult<Shift[]>) => void): void {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiArrayResultFromParse(response, Shift.parse, "warn"));
        };
        BryxApi.http.get("/users/me/shifts", null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static changeShiftStatus(shift: Shift, shiftStatus: ShiftStatus, callback: (result: ApiResult<null>) => void) {
        const body = {
            scheduleMode: ShiftStatus[shiftStatus],
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put(`/users/me/shifts/${shift.id}`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static changeAlertSetting(shift: Shift, filter: Filter, setting: AlertSetting, callback: (result: ApiResult<null>) => void) {
        let body;
        switch (setting.type) {
            case AlertSettingType.none:
                body = {alert: {type: "none"}};
                break;
            case AlertSettingType.silent:
                body = {alert: {type: "silence"}};
                break;
            case AlertSettingType.audio:
                body = {alert: {type: "audio", fileName: setting.soundId}};
                break;
        }

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };

        BryxApi.http.put(`/users/me/shifts/${shift.id}/filters/${filter.id}`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    // Map

    public static getMapClients(callback: (result: ApiResult<MapClient[]>) => void): void {
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiArrayResultFromParse(response, MapClient.parse, "warn"));
        };
        BryxApi.http.get("/users/me/team/locations", null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    // Messaging

    public static sendMessage(content: MessageParameter, groupId: string, callback: (result: ApiResult<Message>) => void) {
        let body;
        switch (content.type) {
            case MessageContentType.text:
                body = {type: "text", text: content.text};
                break;
            case MessageContentType.image:
                body = {type: "image", image: content.image};
                break;
            default:
                throw Error(`Failed to send message with parameter: ${JSON.stringify(content)}`);
        }

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, Message.parse));
        };

        BryxApi.http.post(`/groups/${groupId}/messages`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static loadOldMessages(groupId: string, before: Date, callback: (result: ApiResult<Message[]>) => void) {
        const params = {
            before: Math.floor(before.getTime() / 1000),
        };
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiArrayResultFromParse(response, Message.parse, "warn"));
        };
        BryxApi.http.get(`/groups/${groupId}/messages`, params, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static getImageUrl(imageId: string, thumb: boolean = false): string {
        return `${BryxApi.apiRoot}/images/${imageId}?${thumb ? "thumbnail=true" : ""}&apiKey=${BryxLocal.getApiKey()}`;
    }

    public static getHydrantSvgUrl(color: string): string {
        return `${BryxApi.apiRoot}/assets/hydrant.${encodeURIComponent(color)}.svg`;
    }

    public static updateLastReadTime(groupId: string, lastReadTime: Date, callback: (result: ApiResult<null>) => void) {
        const body = {
            before: Math.floor(lastReadTime.getTime() / 1000),
        };
        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.put(`/groups/${groupId}/messages/lastread`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    // Patients

    public static createPatient(injuries: Injury[], sex: Sex, age: number, jobId: string, callback: (result: ApiResult<Patient>) => void) {
        const body = {
            injuries: injuries.map(i => i.toObject()),
            sex: Sex[sex],
            age: age,
            jobId: jobId,
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, Patient.parse));
        };

        BryxApi.http.post("/patients", null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static alertPatient(patientId: string, hospitalId: string, customEta: number | null, rigId: string | null, callback: (result: ApiResult<Patient>) => void): void {
        const body = {
            hospital: hospitalId,
            customEta: customEta,
            rigId: rigId,
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, Patient.parse));
        };
        BryxApi.http.put(`/patients/${patientId}/alert`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    public static updateVitals(patientId: string, vitals: Vitals, callback: (result: ApiResult<Patient>) => void) {
        const body = {
            vitals: vitals.toObject(),
        };

        const wrappedCallback = (request: HttpRequest, response: HttpResponse) => {
            callback(apiResultFromParse(response, Patient.parse));
        };

        BryxApi.http.put(`/patients/${patientId}`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }

    // WebSocket Functions

    public static subscribeToNewJobs(key: string, fastForwardMode: "reset" | "resume", onUpdate: (result: ApiResult<JobsListUpdate>) => void) {
        const params = {
            fastForwardMode: fastForwardMode,
        };
        BryxWebSocket.shared.addSubscriber(key, "jobs", message => {
            const update = parseJobsListUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            } else {
                onUpdate(update);
            }
        }, 0, params);
    }

    public static subscribeToJob(key: string, jobId: string, onUpdate: (result: ApiResult<SpecificJobUpdate>) => void) {
        BryxWebSocket.shared.addSubscriber(key, `jobs/${jobId}`, message => {
            const update = parseSpecificJobUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            } else {
                onUpdate(update);
            }
        }, 1);
    }

    public static changeJobListSubscription(key: string, fastForwardMode: "reset" | "resume", resubscribe: boolean) {
        const params = {
            fastForwardMode: fastForwardMode,
        };
        BryxWebSocket.shared.changeSubscription(key, "jobs", params, resubscribe);
    }

    public static acknowledgeJobsListUpdates(updateIds: string[], completion: (result: ApiResult<null>) => void) {
        const data = {
            type: "ack",
            updateIds: updateIds,
        };
        BryxWebSocket.shared.sendUpdate("jobs", data, completion);
    }

    public static subscribeToNotifications(key: string, onUpdate: (result: ApiResult<NotificationUpdate>) => void) {
        BryxWebSocket.shared.addSubscriber(key, "notifications", message => {
            const update = parseNotificationUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            } else {
                onUpdate(update);
            }
        }, 0);
    }

    public static markNotificationRead(notificationIds: string[], completion: (result: ApiResult<null>) => void) {
        const data = {
            notificationIds: notificationIds,
        };
        BryxWebSocket.shared.sendUpdate("notifications", data, completion);
    }

    public static subscribeToGroupsList(key: string, onUpdate: (result: ApiResult<GroupListUpdate>) => void) {
        BryxWebSocket.shared.addSubscriber(key, "groups", message => {
            const update = parseGroupListUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            } else {
                onUpdate(update);
            }
        }, 0);
    }

    public static subscribeToGroup(key: string, groupId: string, onUpdate: (result: ApiResult<GroupUpdate>) => void) {
        const params = {
            messageLimit: 30,
        };
        BryxWebSocket.shared.addSubscriber(key, `groups/${groupId}`, message => {
            const update = parseGroupUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            } else {
                onUpdate(update);
            }
        }, 0, params);
    }

    public static subscribeToPatient(key: string, patientId: string, onUpdate: (result: ApiResult<PatientUpdate>) => void) {
        BryxWebSocket.shared.addSubscriber(key, `patients/${patientId}`, message => {
            const update = parsePatientUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            } else {
                onUpdate(update);
            }
        }, 0);
    }

    public static unsubscribe(key: string) {
        BryxWebSocket.shared.removeSubscriber(key);
    }
}
