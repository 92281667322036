import * as i18n from "i18next";
import {latLng, LatLngBounds} from "leaflet";
import * as React from "react";
import {LayerGroup, Marker, Popup} from "react-leaflet";
import {RouteComponentProps} from "react-router";
import {BryxMap} from "../../components/bryxMap";
import {config} from "../../config";
import {MapClient} from "../../models/mapClient";
import {BryxApi} from "../../utils/bryxApi";
import {BryxColors} from "../../utils/bryxColors";
import {DateUtils} from "../../utils/dateUtils";
import {GeoUtils} from "../../utils/geoUtils";
import {JobListStatus, JobListType, JobManager, JobManagerListObserver, JobsListsStatus} from "../../utils/jobManager";
import {LocationManager, LocationManagerObserver} from "../../utils/locationManager";
import {BryxPreferences, PreferenceManager, PreferenceManagerObserver} from "../../utils/preferenceManager";

interface MapPageState {
    listStatus: JobListStatus;
    mapClients: MapClient[] | null;
    userPosition: Position | null;
    preferences: BryxPreferences;
    currentTime: Date;
}

export class MapPage extends React.Component<RouteComponentProps<{}>, MapPageState> implements JobManagerListObserver, PreferenceManagerObserver, LocationManagerObserver {
    private static readonly BOUNDS_MAX_DISTANCE_RADIUS = 100 * 1000; // 100 km
    private timerId: NodeJS.Timer | null = null;

    constructor(props: RouteComponentProps<{}>, context: any) {
        super(props, context);

        this.state = {
            listStatus: JobManager.getListStatus(JobListType.open, JobManager.shared.jobsListsStatus),
            mapClients: null,
            userPosition: LocationManager.shared.currentPosition,
            preferences: PreferenceManager.shared.preferences,
            currentTime: DateUtils.bryxNow(),
        };
    }

    componentDidMount() {
        JobManager.shared.registerListObserver(this);
        PreferenceManager.shared.registerObserver(this);
        LocationManager.shared.registerObserver(this);
        this.loadClients();
        this.timerId = setInterval(() => {
            this.setState({currentTime: DateUtils.bryxNow()});
            this.loadClients();
        }, 5000);
    }

    componentWillUnmount() {
        if (this.timerId != null) {
            clearInterval(this.timerId);
        }
        JobManager.shared.unregisterListObserver(this);
        PreferenceManager.shared.unregisterObserver(this);
        LocationManager.shared.unregisterObserver(this);
    }

    private loadClients() {
        BryxApi.getMapClients(result => {
            if (result.success == true) {
                this.setState({mapClients: result.value});
            } else {
                config.warn(`Failed to load map clients: ${result.debugMessage}`);
            }
        });
    }

    // PreferenceManagerObserver Functions

    preferencesManagerDidUpdatePreferences(newPrefs: BryxPreferences): void {
        this.setState({preferences: newPrefs});
    }

    // JobManagerListObserver Functions

    jobManagerDidUpdateJobsListsStatus(status: JobsListsStatus): void {
        this.setState({listStatus: JobManager.getListStatus(JobListType.open, status)});
    }

    // LocationManagerObserver Functions

    locationManagerDidUpdatePosition(position: Position): void {
        this.setState({userPosition: position});
    }

    locationManagerDidClearPosition(): void {
        this.setState({userPosition: null});
    }

    render() {
        const {preferences, mapClients, userPosition, listStatus} = this.state;

        const clientMarkers = mapClients != null ? mapClients.map(c => (
            <Marker
                key={c.id}
                clickable
                icon={BryxMap.mapClientIcon(c)}
                position={GeoUtils.geoJsonToLatLng(c.location)}
            >
                <Popup autoPan={false} keepInView={false} zoomAnimation={false} offset={[0, -40]}>
                    <div className="client-popup-content">
                        <span style={{fontWeight: "bold"}}>
                            {c.name}
                        </span>
                        <span style={{color: BryxColors.gray}}>
                                {DateUtils.duration(c.lastUpdated, this.state.currentTime).humanize(true)}
                        </span>
                    </div>
                </Popup>
            </Marker>
        )) : [];

        const jobsWithLocations = listStatus.key == "active" ? listStatus.jobs.filter(j => j.centroid != null) : [];
        let bounds: LatLngBounds | null;
        const userLatLng = userPosition != null ? latLng(userPosition.coords.latitude, userPosition.coords.longitude) : null;
        if (userLatLng != null) {
            const boundingCoordinates = jobsWithLocations.filter(j => {
                const jobLatLng = latLng(j.centroid!.coordinates.slice(0, 2).reverse() as [number, number]);
                return userLatLng.distanceTo(jobLatLng) <= MapPage.BOUNDS_MAX_DISTANCE_RADIUS;
            }).map(j => j.centroid!.coordinates.slice(0, 2).reverse());
            bounds = boundingCoordinates.length > 0 ? GeoUtils.boundsFromLatLngs(boundingCoordinates as [[number, number]]) : userLatLng.toBounds(MapPage.BOUNDS_MAX_DISTANCE_RADIUS);
        } else {
            const boundingCoordinates = jobsWithLocations.map(j => j.centroid!.coordinates.slice(0, 2).reverse());
            bounds = GeoUtils.boundsFromLatLngs(boundingCoordinates as [[number, number]]);
        }

        const jobMarkers = jobsWithLocations.map(j => (
            <Marker
                key={j.id}
                clickable
                icon={BryxMap.jobTypeToIcon(j.typeInformation.type)}
                position={GeoUtils.geoJsonToLatLng(j.centroid!)}>
                <Popup autoPan={false} keepInView={false} zoomAnimation={false} offset={[0, -40]}>
                    <div className="job-popup-content" onClick={() => this.props.history.push(`/jobs/${j.id}`)}>
                            <span style={{fontWeight: "bold"}}>
                                {[j.shortDepartment, j.typeInformation.code, j.synopsis].join(" - ")}
                            </span>
                        <span style={{color: BryxColors.gray}}>
                                {j.address && j.address.originalWithLocationInfo}
                            </span>
                        <span style={{color: BryxColors.lightGray}}>
                                {DateUtils.formatDateTime(j.creationTime, preferences)}
                            </span>
                    </div>
                </Popup>
            </Marker>
        ));

        return (
            <div id="map-page">
                <BryxMap
                    className="bryx-map"
                    bounds={bounds || undefined}
                    boundsOptions={{padding: [20, 20]}}
                    scrollWheelZoom
                >
                    <LayerGroup>
                        {jobMarkers}
                    </LayerGroup>
                    <LayerGroup>
                        {clientMarkers}
                    </LayerGroup>
                    {userLatLng != null && userPosition != null ? (
                        <Marker
                            icon={BryxMap.myLocationIcon}
                            position={userLatLng}
                            clickable
                        >
                            <Popup autoPan={false} keepInView={false} zoomAnimation={false} offset={[0, -10]}>
                                <div>
                                    <span
                                        style={{display: "block", fontWeight: "bold"}}>{i18n.t("map.myLocation")}</span>
                                    <span
                                        style={{display: "block"}}>{i18n.t("map.lastUpdatedX", {time: DateUtils.duration(new Date(userPosition.timestamp), this.state.currentTime).humanize(true)})}</span>
                                </div>
                            </Popup>
                        </Marker>
                    ) : null}
                </BryxMap>
            </div>
        );
    }
}
